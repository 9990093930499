import { AfterViewInit, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { faCocktail } from '@fortawesome/free-solid-svg-icons';
import { AppRoute } from '../models/approute';
import { MENULIST } from './../utils/side-bar-menu';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements AfterViewInit {
  constructor(private renderer: Renderer2) { }
  menuList = MENULIST;
  appRoutes: AppRoute[] = [];
  signOut() { }
  @ViewChild('toolbar') toolbar: ElementRef | undefined;
  @ViewChild('body') body: ElementRef | undefined;

  @HostListener('window:resize', ['$event'])
  onResize() {
    var h = this.toolbar!.nativeElement.offsetHeight;
    h = "calc(100vh - " + h + "px)";
    this.renderer.setStyle(this.body!.nativeElement, "height", h);
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

}
