import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private httpClient: HttpClient) { }

  private REST_API_SERVER = environment.rest_service_target;

  public fetchAWSDynamoDBData(tableName: string) {
    let url = this.REST_API_SERVER + "dynamoDB/" + tableName + "/all";
    console.log("url::", url);
    return this.httpClient.get(url);
  }

  public createNewDeviceMasterConfig(data: any) {
    let url = this.REST_API_SERVER + "dynamoDB/DeviceConfigTable/create";
    return this.httpClient.post(url, data, {});
  }

  public fetchAllUsers() {
    let url = this.REST_API_SERVER + "secure/users/all";
    return this.httpClient.get(url);
  }

  public addNewUser(user: any) {
    let url = this.REST_API_SERVER + "secure/users/create";
    return this.httpClient.post(url, user);
  }
  public fetchDeviceId() {
    let url = this.REST_API_SERVER + "dynamoDB/DeviceConfigTable/AllDeviceId";
    return this.httpClient.get(url);
  }
}
