<div class="container mt-5 mb-5">
  <div *ngIf="isLoading; else mainContent">
    <p style="text-align: center;">Loading...</p>
  </div>

  <ng-template #mainContent>
    <div class="row justify-content-between">
      <div class="col-md-auto">
        <h2>DeviceVehicle Config</h2>
      </div>
      <form [formGroup]="deviceConfigFormGroup">
        <div class="row justify-content-md-center">
          <div class="col-md-auto">
            <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" formControlName="vendorNameControl">
              <!-- <option disabled selected>Choose...</option> -->
              <option *ngFor="let v of vendors" value="{{v}}">{{ v }}</option>
            </select>
          </div>
          <div class="col-md-auto">
            <input type="deviceId" class="form-control" aria-describedby="deviceId" placeholder="DeviceId"
              formControlName="deviceIdControl">
          </div>
          <div class="col-md-auto">
            <button type="button" class="btn btn-primary" (click)="addConfig();">Add</button>
          </div>
        </div>
      </form>
    </div>


    <div class="row justify-content-md-center">
      <div class="col-12">
        <p>Total Records {{ configInfo.length }}</p>
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">DeviceId</th>
              <th scope="col">Provision Status</th>
              <th scope="col">Provision Time</th>
              <th scope="col">Config Status</th>
              <th scope="col">Config Time</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let r of configInfo, let i =  index">
              <th scope="row">{{ i + 1 }}</th>
              <td>{{ r.deviceId }}</td>
              <td>{{ r.provisioningStatus }}</td>
              <td>{{ r.provisioningTime }}</td>
              <td>{{ r.configStatus }}</td>
              <td>{{ r.configTime }}</td>
            </tr>
          </tbody>
        </table>

        

      </div>
    </div>
  </ng-template>
</div>