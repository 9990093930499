import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RestService } from '../services/rest.service';

export interface Config {
  deviceId: string;
  provisioningStatus: string;
  provisioningTime: string;
  configStatus: string;
  configTime: string;
}

@Component({
  selector: 'app-device-master-config',
  templateUrl: './device-master-config.component.html',
  styleUrls: ['./device-master-config.component.css']
})
export class DeviceMasterConfigComponent implements OnInit {

  constructor(private restService: RestService) { }

  isLoading: boolean = false;

  vendors: string[] = ["AXD_", "SRP_"];
  deviceId: string | undefined;

  configInfo: Config[] = [];

  deviceConfigFormGroup!: FormGroup;
  deviceIdControl!: FormControl;
  vendorNameControl!: FormControl;

  getDateTimeFromMilliS(ts: number): string {
    let dateFormatConfig: any = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true };
    let d: Date = new Date(parseInt(ts.toString()));
    let t = d.toLocaleDateString('en-in', dateFormatConfig).toUpperCase();
    return t;
  }

  getStatus(flag: string): string {
    return parseInt(flag) == 1 ? "True" : "Pending";
    if (parseInt(flag)) {
      return "True";
    }
    else return "Pending";
  }

  ngOnInit(): void {
    this.fetchData();
    this.initForm();
  }

  addConfig() {
    if (this.deviceIdControl.value.toString().trim().length > 2) {
      let dId = this.vendorNameControl.value + this.deviceIdControl.value;
      let duplicate: boolean = this.configInfo.some((data) => { return data.deviceId == dId });
      if (!duplicate) {
        this.isLoading = true;
        this.deviceIdControl.reset();
        let data = {
          "DeviceId": dId,
          "DC_sendingIntervalNormalMode": 5000,
          "DC_BroadcastEnabled": true,
          "DC_SoftwareVer": "1",
          "DC_ConfigParam5": {},
          "DC_ConfigParam4": [],
          "DC_BroadcastInterval": "0",
          "DC_ConfigParam1": "1",
          "DC_ConfigParam3": "93.34.45.6",
          "DC_ConfigParam2": "0",
          "DC_sendingIntervalSleepMode": 900000,
          "DC_sensingInterval": 500,
          "VC_serviceStatus": "0",
          "VC_AntitheftStatus": "0",
          "VC_ControlParam3": "1",
          "VC_ControlParam2": "0",
          "VC_ControlParam1": "1",
          "VC_enabled": "1",
          "Timestamp": (new Date().getTime()).toString(),
          "isEnabled": "0",
          "DeviceConfigStatus": "0",
          "GT_ConfigStatus": "0",
          "GT_ConfigTime": "",
          "GT_ProvisioningStatus": "0",
          "GT_ProvisioningTime": ""
        };
        console.log(data);
        this.restService.createNewDeviceMasterConfig(data).subscribe((d: any) => {
          this.fetchData();
          this.isLoading = false;
        }, (error) => {
          console.log("error::", error);
          this.isLoading = false;
        });
      }
    }
  }

  initForm() {
    this.deviceIdControl = new FormControl('', Validators.required);
    this.vendorNameControl = new FormControl('AXD_', Validators.required);
    this.deviceConfigFormGroup = new FormGroup({
      deviceIdControl: this.deviceIdControl,
      vendorNameControl: this.vendorNameControl
    });
  }

  fetchData() {
    this.isLoading = true;
    this.restService.fetchAWSDynamoDBData("DeviceConfigTable").subscribe(
      (data: any) => {
        this.configInfo = [];
        this.isLoading = false;
        console.log(data);
        if (data.success == true) {
          const result = data.result.Items;
          console.log(result);
          result.forEach((d: any) => {
            let DeviceId = d['DeviceId'];
            let provisioningStatus = d['GT_ProvisioningStatus'];
            let provisioningTime = d['GT_ProvisioningTime'];
            let configStatus = d['GT_ConfigStatus'];
            let configTime = d['GT_ConfigTime'];


            if (provisioningTime != "") {
              provisioningTime = this.getDateTimeFromMilliS(provisioningTime);
            }

            if (configTime != "") {
              configTime = this.getDateTimeFromMilliS(configTime);
            }

            configStatus = this.getStatus(configStatus);
            provisioningStatus = this.getStatus(provisioningStatus);

            this.configInfo.push({
              deviceId: DeviceId,
              provisioningStatus: provisioningStatus,
              provisioningTime: provisioningTime,
              configStatus: configStatus,
              configTime: configTime,
            });
          });
        }
      },
      (error) => {
        console.log("error::", error);
      },
    );
  }

}
