<div class="container mt-5 mb-5">
  <div *ngIf="isLoading; else mainContent">
    <p style="text-align: center">Loading...</p>
  </div>

  <ng-template #mainContent>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <h1 class="title">
          <span>All Users</span>
          <button mat-mini-fab color="primary" (click)="addUser()">
            <mat-icon>add</mat-icon>
          </button>
        </h1>
        <!-- <p>Total Records {{ users.length }}</p> -->
        <div class="table-container mat-elevation-z8">
          <table mat-table [dataSource]="users">
            <ng-container matColumnDef="srno" sticky>
              <th mat-header-cell *matHeaderCellDef>#</th>
              <td mat-cell *matCellDef="let element; index as i">
                {{ i + 1 }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
            </ng-container>

            <ng-container matColumnDef="MobileNo">
              <th mat-header-cell *matHeaderCellDef>Mobile #</th>
              <td mat-cell *matCellDef="let element">{{ element.MobileNo }}</td>
            </ng-container>

            <ng-container matColumnDef="AadharNo">
              <th mat-header-cell *matHeaderCellDef>Aadhar #</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Aadhar_Number }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Address">
              <th mat-header-cell *matHeaderCellDef>Address</th>
              <td mat-cell *matCellDef="let element">{{ element.Address }}</td>
            </ng-container>

            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Profile_Status }}
              </td>
            </ng-container>

            <ng-container matColumnDef="RC">
              <th mat-header-cell *matHeaderCellDef>RC #</th>
              <td mat-cell *matCellDef="let element">
                {{ element.RC_Number }}
              </td>
            </ng-container>

            <ng-container matColumnDef="UniqueId">
              <th mat-header-cell *matHeaderCellDef>UniqueId</th>
              <td mat-cell *matCellDef="let element">
                {{ element.User_Profile_Id }}
              </td>
            </ng-container>

            <ng-container matColumnDef="VehicleBrand">
              <th mat-header-cell *matHeaderCellDef>Vehicle Brand</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Vehicle_Brand }}
              </td>
            </ng-container>

            <ng-container matColumnDef="VehicleModel">
              <th mat-header-cell *matHeaderCellDef>Vehicle Model</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Vehicle_Model }}
              </td>
            </ng-container>

            <ng-container matColumnDef="VehicleNo">
              <th mat-header-cell *matHeaderCellDef>Vehicle #</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Vehicle_Number }}
              </td>
            </ng-container>

            <ng-container matColumnDef="VehicleYr">
              <th mat-header-cell *matHeaderCellDef>Vehicle Year</th>
              <td mat-cell *matCellDef="let element">
                {{ element.Vehicle_Year }}
              </td>
            </ng-container>
            <ng-container matColumnDef="key">
              <th mat-header-cell *matHeaderCellDef>Password</th>
              <td mat-cell *matCellDef="let element">
                {{ element.key }}
              </td>
            </ng-container>
            <ng-container matColumnDef="DeviceId" stickyEnd>
              <th mat-header-cell *matHeaderCellDef>DeviceId</th>
              <td mat-cell *matCellDef="let element">
                {{ element.DeviceId }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns"></tr>

            <tr mat-row *matRowDef="let row; columns: columns"></tr>
          </table>
        </div>
      </div>
    </div>
  </ng-template>
</div>