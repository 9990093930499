
import { faUser, IconDefinition } from "@fortawesome/free-solid-svg-icons";

class MenuItem {
  title!: string;
  icon!: IconDefinition;
  children!: MenuChild[];
}

class MenuChild {
  title!: string;
  icon!: IconDefinition;
}

export let MENULIST: MenuItem[] = [
  {
    title: "Users", icon: faUser, children: []
  }
];