import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RestService } from 'src/app/services/rest.service';
import 'sweetalert2/src/sweetalert2.scss';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css'],
})
export class NewUserComponent implements OnInit {
  newUserForm!: FormGroup;
  name!: FormControl;
  mobileNo!: FormControl;
  emailAddr!: FormControl;
  deviceId!: FormControl;
  listId: any[] = [];
  filteredOptions!: Observable<any[]>;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

  constructor(
    private restService: RestService,
    public dialogRef: MatDialogRef<NewUserComponent>
  ) {
    this.gethDeviceId();
  }

  ngOnInit(): void {
    this.name = new FormControl('', [
      Validators.required,
      Validators.pattern('^[A-Za-z0-9 ]+$'),
    ]);
    this.mobileNo = new FormControl('', [
      Validators.required,
      Validators.maxLength(10),
    ]);
    this.deviceId = new FormControl('', Validators.required);
    this.filteredOptions = this.deviceId.valueChanges.pipe(
      map((term) => {
        return this.listId.filter((Option) => Option.includes(term));
      })
    );
    this.emailAddr = new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailPattern),
    ]);
    this.newUserForm = new FormGroup({
      name: this.name,
      mobileNo: this.mobileNo,
      deviceId: this.deviceId,
      emailAddr: this.emailAddr,
    });
  }
  gethDeviceId() {
    this.restService.fetchDeviceId().subscribe(
      (data: any) => {
        if (data.success == true) {
          this.listId = data.result;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  saveUser() {
    if (this.newUserForm.valid) {
      const fd = this.newUserForm.value;
      let data = {
        name: fd.name,
        deviceId: fd.deviceId,
        phone: parseInt(fd.mobileNo),
        email: fd.emailAddr,
      };
      this.dialogRef.close(data);
    }
  }
  onClose() {
    Swal.fire({
      title: 'Are you sure',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Go Back',
    }).then((result) => {
      if (result.value == true) {
        this.dialogRef.close();
      }
    });
  }
}
