import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { User } from '../models/user';
import { RestService } from '../services/rest.service';
import { NewUserComponent } from './new-user/new-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  isLoading: boolean = false;
  columns: string[] = ["srno", "Name", "MobileNo", "AadharNo", "Address", "Status", "RC", "UniqueId", "VehicleBrand", "VehicleModel", "VehicleNo", "VehicleYr", "key", "DeviceId"];
  users: User[] = [];

  constructor(private restService: RestService, public dialog: MatDialog) { }
  ngOnInit(): void {
    this.fetchUsers();
  }

  addUser() {
    const dialogRef = this.dialog.open(NewUserComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.saveRecord(result);
    });
  }
  saveRecord(result: any) {
    this.restService.addNewUser(result).subscribe((data: any) => {
      if (data.success == true) {
        Swal.fire({
          icon: "success",
          title: "User Added",
        });
      } else if (data.success == false) {
        Swal.fire({
          icon: "error",
          title: "Error Occured",
          text: data.error ?? "",
        });
      }
    }, (error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error Occured",
      });
    }, () => this.fetchUsers());
  }

  fetchUsers() {
    this.restService.fetchAllUsers().subscribe((data: any) => {
      console.log(data);
      if (data.success == true && Array.isArray(data.result)) {
        console.log(data.result);
        const res = data.result;
        this.users = [];
        res.forEach((r: any) => {
          this.users.push({
            Aadhar_Number: r.Aadhar_Number,
            Address: r.Address,
            CreateTimestamp: r.CreateTimestamp,
            Created_By: r.Created_By,
            DeviceId: r.DeviceId,
            MobileNo: r.MobileNo,
            Name: r.Name,
            Profile_Status: r.Profile_Status,
            RC_Number: r.RC_Number,
            Update_Timestamp: r.Update_Timestamp,
            Updated_By: r.Updated_By,
            User_Profile_Id: r.User_Profile_Id,
            Vehicle_BS_Stage: r.Vehicle_BS_Stage,
            Vehicle_Brand: r.Vehicle_Brand,
            Vehicle_Model: r.Vehicle_Model,
            Vehicle_Number: r.Vehicle_Number,
            Vehicle_Year: r.Vehicle_Year,
            key: r.key
          });
        });
      }
      else {
        console.log("Error");
        Swal.fire({
          icon: "error",
          title: "Error Occured",
        });
      }
    }, (error) => {
      console.log(error);
    });
  }
}
