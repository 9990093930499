<div #toolbar>
  <mat-toolbar color="primary" class="mat-elevation-z2">
    <span>Green Tiger</span>
    <span class="custom-spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
      <mat-icon>settings</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item mat-menu-item *ngFor="let route of appRoutes" class="pl-3 pr-3"
        routerLink="{{route.routePath}}">
        {{route.title}}
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="signOut()">
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar>
</div>
<div style="padding: 1rem 0; overflow: scroll;" #body>
  <mat-drawer-container class="example-container pr-1">
    <mat-drawer mode="side" opened>
      <mat-nav-list>
        <div mat-subheader>Modules</div>
        <!-- <mat-list-item *ngFor="let ml of menuList">
          <mat-icon mat-list-icon>folder</mat-icon>
          <div mat-line>{{ml.title}}</div>
        </mat-list-item>
        <mat-divider></mat-divider> -->
        <a mat-list-item *ngFor="let ml of menuList">
          <fa-icon [icon]="ml.icon"></fa-icon>
          &nbsp;&nbsp;&nbsp;{{ml.title}}
        </a>
      </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content>
      <!-- Drawer Content -->
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>