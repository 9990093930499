<div class="container mt-1 mb-1">
  <form class="form" [formGroup]="newUserForm" (ngSubmit)="saveUser()">
    <div class="row justify-content-between-center">
      <div class="col-12 title">
        <h2>Add New User</h2>
        <button mat-mini-fab color="primary" (click)="onClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Govind Kumar" formControlName="name">
          <mat-error *ngIf="name.invalid">Invalid name</mat-error>
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Select DeviceId</mat-label>
          <input matInput type="text" [matAutocomplete]="auto" [formControl]="deviceId">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option [value]="device_id" *ngFor="let device_id of filteredOptions | async">
              {{device_id}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="deviceId.invalid">Invalid device ID</mat-error>
          <mat-icon matSuffix>electric_moped</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input type="tel" matInput placeholder="9876543210" formControlName="mobileNo" maxlength="10">
          <mat-error *ngIf="mobileNo.invalid">Invalid phone no</mat-error>
          <mat-icon matSuffix>smartphone</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="outline">
          <mat-label>Email Address</mat-label>
          <input type="email" matInput placeholder="pathik@greentiger.in" formControlName="emailAddr">
          <mat-error *ngIf="emailAddr.invalid">Invalid email id</mat-error>
          <mat-icon matSuffix>email</mat-icon>
        </mat-form-field>
      </div>
    </div>
    <div class="row justify-content-end">
      <div class="col-6 button-container">
        <button mat-flat-button color="primary" type="submit">Save</button>
      </div>
    </div>
  </form>
</div>